import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import React from 'react';
import { toast } from 'react-toastify';
import { AppThunk } from '../../context/store';
import { GET_ONSITE_REPONDERS_ENDPOINT } from '../../lib/globals';
import { CARDHOLDER_API_RESPONSE_SCHEMA, PII_MASK } from '../../lib/constants';
import { getResponderBadgetimeAndEtString } from '../../lib/helpers/helpers';

const {
    FIRST_NAME,
    LAST_NAME,
    TITLE,
    LOGIN,
    EMPID,
    IMG_URL,
    BADGE_COLOR,
    MANAGER,
    MANAGER_FIRST_NAME,
    MANAGER_LAST_NAME,
    DEVICE_NAME,
    EVENT_TIMESTAMP
} = CARDHOLDER_API_RESPONSE_SCHEMA;

const { doRequest } = asyncRequest;

export interface responderType {
    sites: string[];
    firstName: string;
    lastName: string;
    title: string;
    login: string;
    empId: string;
    imgURL: string;
    badgeColor: string;
    manager: string;
    managerFirstName: string;
    managerLastName: string;
    deviceName: string;
    eventTimestamp: number;
}

export interface availableResponders {
    firstResponders: { [empId: string]: responderType };
    imt: { [empId: string]: responderType };
    whs: { [empId: string]: responderType };
    firstResponderCount: number | null;
    imtCount: number | null;
    whsCount: number | null;
}

interface ResponderDashboardState {
    isGettingResponderDashboard: boolean;
    isRefreshingResponderDashboard: boolean;
    cardholders: any;
    responders: availableResponders | null;
    filteredResponders: availableResponders | null;
    lastRefresh: number;
}

export const initialState: ResponderDashboardState = {
    isGettingResponderDashboard: false,
    isRefreshingResponderDashboard: false,
    cardholders: null,
    responders: null,
    filteredResponders: null,
    lastRefresh: 0
};

const responderDashboardSlice = createSlice({
    name: 'responderDashboard',
    initialState,
    reducers: {
        resetOnsiteResponders(state) {
            state.cardholders = null;
            state.responders = null;
        },
        startGetOnsiteResponders(state) {
            state.isGettingResponderDashboard = true;
        },
        startRefreshOnsiteResponders(state) {
            state.isRefreshingResponderDashboard = true;
        },
        getOnsiteRespondersSucceeded(state, action: PayloadAction<any>) {
            const { cardholders, responders } = action.payload;
            state.cardholders = cardholders;
            state.responders =
                !responders.firstResponderCount && !responders.imtCount && !responders.whsCount
                    ? {}
                    : responders;
            state.isGettingResponderDashboard = false;
            state.isRefreshingResponderDashboard = false;
            state.lastRefresh = Date.now();
        },
        finishGetOnsiteResponders(state) {
            state.isGettingResponderDashboard = false;
            state.isRefreshingResponderDashboard = false;
        },
        setFilteredResponders(state, action: PayloadAction<any>) {
            const { filteredResponders } = action.payload;
            state.filteredResponders = filteredResponders;
        }
    }
});

export const {
    resetOnsiteResponders,
    startGetOnsiteResponders,
    startRefreshOnsiteResponders,
    getOnsiteRespondersSucceeded,
    finishGetOnsiteResponders,
    setFilteredResponders
} = responderDashboardSlice.actions;

export default responderDashboardSlice.reducer;

export const getOnsiteResponders = (siteNames: string[], isRefresh = false): AppThunk => async (dispatch) => {
    try {
        if (!siteNames.length) {
            dispatch(resetOnsiteResponders());
        } else {
            isRefresh ? dispatch(startRefreshOnsiteResponders()) : dispatch(startGetOnsiteResponders());
            const request = { siteNames };

            const { data } = await doRequest(
                GET_ONSITE_REPONDERS_ENDPOINT,
                request,
                GuardianAuth.createRequestAuthHeader()
            );

            if (data) {
                const siteKeys = Object.keys(data);
                let cardholderData: any = {};
                const combinedFirstResponders: { [empId: string]: any } = {};
                const combinedImt: { [empId: string]: any } = {};
                const combinedWhs: { [empId: string]: any } = {};
                let firstResponderCount: number | null = null;
                let imtCount: number | null = null;
                let whsCount: number | null = null;

                siteKeys.forEach((siteName) => {
                    const { cardholders, firstResponders, imt, whs } = data[siteName];
                    cardholderData = {
                        ...cardholderData,
                        ...cardholders
                    };

                    if (firstResponders) {
                        if (!firstResponderCount) {
                            firstResponderCount = firstResponders.length;
                        } else {
                            firstResponderCount += firstResponders.length;
                        }

                        firstResponders.forEach((empId: string) => {
                            if (combinedFirstResponders[empId]) {
                                combinedFirstResponders[empId].sites.push(siteName);
                            } else {
                                combinedFirstResponders[empId] = {
                                    sites: [siteName],
                                    [EMPID]: cardholders[empId][EMPID],
                                    [FIRST_NAME]: cardholders[empId][FIRST_NAME],
                                    [LAST_NAME]: cardholders[empId][LAST_NAME],
                                    [TITLE]: cardholders[empId][TITLE],
                                    [LOGIN]: cardholders[empId][LOGIN],
                                    [IMG_URL]: cardholders[empId][IMG_URL],
                                    [MANAGER]: cardholders[empId][MANAGER],
                                    [MANAGER_FIRST_NAME]: cardholders[empId][MANAGER_FIRST_NAME],
                                    [MANAGER_LAST_NAME]: cardholders[empId][MANAGER_LAST_NAME],
                                    [BADGE_COLOR]: cardholders[empId][BADGE_COLOR],
                                    [DEVICE_NAME]: cardholders[empId][DEVICE_NAME],
                                    [EVENT_TIMESTAMP]:
                                        cardholders[empId][EVENT_TIMESTAMP] === PII_MASK
                                            ? PII_MASK
                                            : getResponderBadgetimeAndEtString(
                                                  cardholders[empId][EVENT_TIMESTAMP]
                                              )
                                };
                            }
                        });
                    }

                    if (imt) {
                        if (!imtCount) {
                            imtCount = imt.length;
                        } else {
                            imtCount += imt.length;
                        }

                        imt.forEach((empId: string) => {
                            if (combinedImt[empId]) {
                                combinedImt[empId].sites.push(siteName);
                            } else {
                                combinedImt[empId] = {
                                    sites: [siteName],
                                    [EMPID]: cardholders[empId][EMPID],
                                    [FIRST_NAME]: cardholders[empId][FIRST_NAME],
                                    [LAST_NAME]: cardholders[empId][LAST_NAME],
                                    [TITLE]: cardholders[empId][TITLE],
                                    [LOGIN]: cardholders[empId][LOGIN],
                                    [IMG_URL]: cardholders[empId][IMG_URL],
                                    [MANAGER]: cardholders[empId][MANAGER],
                                    [MANAGER_FIRST_NAME]: cardholders[empId][MANAGER_FIRST_NAME],
                                    [MANAGER_LAST_NAME]: cardholders[empId][MANAGER_LAST_NAME],
                                    [BADGE_COLOR]: cardholders[empId][BADGE_COLOR],
                                    [DEVICE_NAME]: cardholders[empId][DEVICE_NAME],
                                    [EVENT_TIMESTAMP]:
                                        cardholders[empId][EVENT_TIMESTAMP] === PII_MASK
                                            ? PII_MASK
                                            : getResponderBadgetimeAndEtString(
                                                  cardholders[empId][EVENT_TIMESTAMP]
                                              )
                                };
                            }
                        });
                    }

                    if (whs) {
                        if (!whsCount) {
                            whsCount = whs.length;
                        } else {
                            whsCount += whs.length;
                        }

                        whs.forEach((empId: string) => {
                            if (combinedWhs[empId]) {
                                combinedWhs[empId].sites.push(siteName);
                            } else {
                                combinedWhs[empId] = {
                                    sites: [siteName],
                                    [EMPID]: cardholders[empId][EMPID],
                                    [FIRST_NAME]: cardholders[empId][FIRST_NAME],
                                    [LAST_NAME]: cardholders[empId][LAST_NAME],
                                    [TITLE]: cardholders[empId][TITLE],
                                    [LOGIN]: cardholders[empId][LOGIN],
                                    [IMG_URL]: cardholders[empId][IMG_URL],
                                    [MANAGER]: cardholders[empId][MANAGER],
                                    [MANAGER_FIRST_NAME]: cardholders[empId][MANAGER_FIRST_NAME],
                                    [MANAGER_LAST_NAME]: cardholders[empId][MANAGER_LAST_NAME],
                                    [BADGE_COLOR]: cardholders[empId][BADGE_COLOR],
                                    [DEVICE_NAME]: cardholders[empId][DEVICE_NAME],
                                    [EVENT_TIMESTAMP]:
                                        cardholders[empId][EVENT_TIMESTAMP] === PII_MASK
                                            ? PII_MASK
                                            : getResponderBadgetimeAndEtString(
                                                  cardholders[empId][EVENT_TIMESTAMP]
                                              )
                                };
                            }
                        });
                    }
                });

                dispatch(
                    getOnsiteRespondersSucceeded({
                        cardholders: cardholderData,
                        responders: {
                            firstResponders: combinedFirstResponders,
                            imt: combinedImt,
                            whs: combinedWhs,
                            firstResponderCount,
                            imtCount,
                            whsCount
                        }
                    })
                );
            }
        }
    } catch (error) {
        toast.error(
            <ToastErrorMessage header={'GetOnsiteResponders request failed.'} errorMessage={error.message} />
        );
    }
};

export const filterResponders = (responders: any, searchValue: string): AppThunk => async (dispatch) => {
    const filterededFirstResponders: { [empId: string]: any } = {};
    const filteredImt: { [empId: string]: any } = {};
    const filterededWhs: { [empId: string]: any } = {};
    let filteredFirstResponderCount: number | null = null;
    let filteredImtCount: number | null = null;
    let filteredWhsCount: number | null = null;

    if (responders?.firstResponders) {
        Object.keys(responders.firstResponders).forEach((empId: string) => {
            const responder = responders.firstResponders[empId] as responderType;
            if (
                //@ts-ignore
                responder[FIRST_NAME].toLowerCase().includes(searchValue.toLowerCase()) ||
                //@ts-ignore
                responder[LAST_NAME].toLowerCase().includes(searchValue.toLowerCase()) ||
                //@ts-ignore
                responder[LOGIN].toLowerCase().includes(searchValue.toLowerCase()) ||
                //@ts-ignore
                responder[TITLE].toLowerCase().includes(searchValue.toLowerCase()) ||
                responder.sites
                    .join()
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            ) {
                filterededFirstResponders[empId] = responder;
            }
        });
        filteredFirstResponderCount = Object.keys(filterededFirstResponders).length;
    }

    if (responders?.imt) {
        Object.keys(responders.imt).forEach((empId: string) => {
            const responder = responders.imt[empId] as responderType;
            if (
                //@ts-ignore
                responder[FIRST_NAME].toLowerCase().includes(searchValue.toLowerCase()) ||
                //@ts-ignore
                responder[LAST_NAME].toLowerCase().includes(searchValue.toLowerCase()) ||
                //@ts-ignore
                responder[LOGIN].toLowerCase().includes(searchValue.toLowerCase()) ||
                //@ts-ignore
                responder[TITLE].toLowerCase().includes(searchValue.toLowerCase()) ||
                responder.sites
                    .join()
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            ) {
                filteredImt[empId] = responder;
            }
        });
        filteredImtCount = Object.keys(filteredImt).length;
    }

    if (responders?.whs) {
        Object.keys(responders.whs).forEach((empId: string) => {
            const responder = responders.whs[empId] as responderType;
            if (
                //@ts-ignore
                responder[FIRST_NAME].toLowerCase().includes(searchValue.toLowerCase()) ||
                //@ts-ignore
                responder[LAST_NAME].toLowerCase().includes(searchValue.toLowerCase()) ||
                //@ts-ignore
                responder[LOGIN].toLowerCase().includes(searchValue.toLowerCase()) ||
                //@ts-ignore
                responder[TITLE].toLowerCase().includes(searchValue.toLowerCase()) ||
                responder.sites
                    .join()
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            ) {
                filterededWhs[empId] = responder;
            }
        });
        filteredWhsCount = Object.keys(filterededWhs).length;
    }

    dispatch(
        setFilteredResponders({
            filteredResponders: {
                firstResponders: filterededFirstResponders,
                imt: filteredImt,
                whs: filterededWhs,
                firstResponderCount: filteredFirstResponderCount,
                imtCount: filteredImtCount,
                whsCount: filteredWhsCount
            }
        })
    );
};

export const clearFilteredResponders = (): AppThunk => async (dispatch) => {
    dispatch(
        setFilteredResponders({
            filteredResponders: null
        })
    );
};
