import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { AvailableRoutes } from '../../AppRoutes';
import { FaUserSecret, FaUserTag } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import '../../css/Admin.css';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';

const PermissionsSidebar = (): JSX.Element => {
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);
    const { webRole, mobileRole } = useSelector(
        (state: RootState) => state.userPermissions?.userPermissionRoles
    );
    const { pathname } = useLocation();
    const userLookupAccess = webRole !== 'User';

    return (
        <Card
            style={{
                minHeight: '100vh',
                maxHeight: '100vh',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
        >
            <Card.Body>
                <Row className='justify-content-center align-items-center'>
                    <Col sm='auto'>
                        <Image
                            className='mb-2'
                            rounded
                            style={{ width: '100%', maxWidth: 150 }}
                            src={userCardholderInformation.imgURL}
                            fluid={true}
                        />
                    </Col>
                </Row>
                <Row className='text-center'>
                    <Col>
                        <h4>{`${userCardholderInformation.firstName} ${userCardholderInformation.lastName}`}</h4>
                    </Col>
                </Row>
                <Row className='mt-n2 text-center'>
                    <Col>
                        <span>{userCardholderInformation.title}</span>
                    </Col>
                </Row>
                <hr />
                <Row className='mt-n1 pl-1'>
                    <span>{`Location: ${userCardholderInformation.location}`}</span>
                </Row>
                <hr />
                <Row className='mt-1 pl-1'>
                    <strong style={{ fontSize: '1.1rem' }}>Rally Point Roles:</strong>
                </Row>
                <Row className='mt-1 pl-1'>
                    <span>Web: {webRole}</span>
                </Row>
                <Row className='mt-0 pl-1'>
                    <span>Mobile: {mobileRole}</span>
                </Row>
                <hr />
                <Nav className='mt-n3 ml-n4 flex-column'>
                    <Nav.Item className='mt-3'>
                        <Nav.Link
                            className='text-secondary'
                            as={NavLink}
                            to={AvailableRoutes.profile}
                            style={{ fontSize: '14pt', textAlign: 'left' }}
                            activeStyle={{ fontSize: '17pt', fontWeight: 'bold', color: '#4f4f4f' }}
                        >
                            <FaUserSecret className='sidebarIcon' />
                            My Permissions
                        </Nav.Link>
                    </Nav.Item>
                    {userLookupAccess && (
                        <Nav.Item className='mt-3'>
                            <Nav.Link
                                className='text-secondary'
                                as={NavLink}
                                to={AvailableRoutes.userLookup}
                                isActive={() =>
                                    pathname.includes('/userLookup') || pathname.includes('/siteLookup')
                                }
                                style={{ fontSize: '15pt', textAlign: 'left' }}
                                activeStyle={{ fontSize: '18pt', fontWeight: 'bold', color: '#4f4f4f' }}
                            >
                                <FaUserTag className='sidebarIcon' />
                                User Lookup
                            </Nav.Link>
                        </Nav.Item>
                    )}
                </Nav>
            </Card.Body>
        </Card>
    );
};

export default PermissionsSidebar;
